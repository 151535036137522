$theme-name: "actimove";
$display-shadows: true;

// Colors
$home-color: #333;
$color-primary: #004e9e;
$color-primary-light: #3371B1;
$color-primary-lighter: #99b8d8;
$color-primary-lightest: #ccdcec;
$color-accent: #8bbd0d;
$color-info-blue: #84b8e1;
$color-red: #950730;
$color-cyan: #009fe3;
$color-turquoise: #00a19a;
$color-aluminium: #aabcc4;
$color-light-black: #666;
$color-primary-light-blue: #F2F7FA;
$color-olive: #5E7E0C;

// Title
$section-title-color: #333;

// Typography
$fontfamily-roboto-regular: RobotoRegular, $fontfamily-fallback;
$fontfamily-lucidaGrande-bold-italic: LucidaGrandeBoldItalic, $fontfamily-fallback;

$fontfamily-regular: $fontfamily-roboto-regular;
$fontfamily-titles-normal: $fontfamily-lucidaGrande-bold-italic;
$fontfamily-titles-thin: $fontfamily-roboto-regular;
$fontfamily-header: $fontfamily-lucidaGrande-bold-italic;
$fontfamily-header-thin: $fontfamily-lucidaGrande-bold-italic;
$fontfamily-regular-italic: $fontfamily-roboto-regular;

// Font sizes
$font-size-h1: (def: 48px, sm: 28px);
$font-size-h2: (def: 40px, md: 28px, sm: 22px);
$font-size-h3: (def: 28px);
$font-size-h4: (def: 22px);
$font-size-paragraph: 16px;
$font-size-section-title: (def: 28px, md: 22px);

// Line height
$line-height-paragraph: 22px;
$line-height-section-title: (def: 32px, md: 26px);

// Buttons
$font-size-btn: 16px;
$btn-border-radius: 0;
$btn-padding: 10px 40px 10px 20px;
$btn-padding-icon: 40px;
$btn-font-family: $fontfamily-regular;
$btn-weight: 600;
$btn-play-background: rgba(0, 0, 0, 0.27);

// Header
$mobile-header-trigger-color: $color-light-black;
$breadcrumb-color: $color-light-black;
$breadcrumb-arrow-color: $color-accent;
$breadcrumb-hover-color: #5E7E0C;
$header-border-bottom-color: #D6DEE3;
$header-border-bottom-width: 1px;
$breadcrumbs-bg-color: $color-white;
$mobile-header-search-close-icon-color: $color-black;
$mobile-header-details-position-bottom: 15px;
$header-country-selector-label-font-size: 14px;
$header-country-selector-label-line-height: 18px;
$header-country-selector-label-color: #666;

// Footer
$footer-disclaimer-line-height: (def: 36px, sm: 26px);
$footer-disclaimer-heading-font-family: $fontfamily-titles-normal;
$footer-disclaimer-heading-color: $home-color;
$footer-disclaimer-footnote-line-height: (def: 28px, sm: 22px);
$footer-disclaimer-footnote-color: $home-color;
$footer-disclaimer-border-color: #D6DEE3;
$footer-disclaimer-border-radius: unset;
$footer-country-selector-label-font-size: 16px;
$footer-country-selector-label-line-height: 22px;
$footer-country-selector-label-color: #666;

// Product intro
$product-intro-guide-popup-label-color: $color-primary;
$product-intro-icon-color: $color-accent;
$product-intro-thumbnail-active-border-color: $color-accent;
$product-intro-panel-close-button: #D6DEE3;
$product-carousel-scrollbar-color: $color-primary;
$product-intro-accordion-title-font-size: 22px;
$product-intro-accordion-title-line-height: 26px;
$product-intro-accordion-arrow: $color-primary;
$product-intro-accordion-border: #D6DEE3;
$product-intro-panel-header: $fontfamily-regular;
$product-intro-badge-font-size: 14px;
$product-intro-badge-line-height: 16px;
$product-intro-accordion-reviews-link-color: $color-primary;
$product-intro-title-font-size: (def: 22px);
$product-intro-title-line-height: (def: 26px);
$product-intro-accordion-how-to-content-line-height: 22px;

// Image carousel
$image-carousel-arrow-color: $color-primary;
$image-carousel-enlarge-stroke-color: $color-primary;

// Power reviews
$pr-idle-color: $color-primary-light-blue;
$pr-star-color: $color-primary;
$pr-block-light-color: $header-gradient-color2;
$pr-block-dark-color: #bdcfde;
$pr-inactive-color: #5d83a6;
$pr-star-0: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="88 103 58 87 27 103 33 69 8 45 43 40 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-25: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="107 45 73 40 58 9 43 40 33 41 33 69 33 69 33 69 33 100 58 87 88 103 82 69 107 45" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/><polygon points="33 69 33 69 33 69 33 69" fill="none" stroke="#{$color-primary}" stroke-width="2" /><polygon points="27 103 33 100 33 69 27 103" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/><polygon points="8 45 33 69 33 41 8 45" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-50: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="58 87 27 103 33 69 8 45 43 40 58 9 58 87" fill="#{$pr-star-color}"/><polygon points="88 103 58 87 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-75: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="82 69 107 45 82 41 82 100 88 103 82 69" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/><polygon points="73 40 58 9 43 40 8 45 33 69 27 103 58 87 82 100 82 41 73 40" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-100: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="88 103 58 87 27 103 33 69 8 45 43 40 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';

// Products
$font-size-product-box-text: 14px;
$product-badge-small-background: $color-primary;
$product-box-background: $color-primary-light-blue;
$products-card-carousel-scrollbar-color: $color-aluminium;
$product-text-color: $home-color;
$product-carousel-title-font-size: (def: 28px, sm: 22px);
$product-carousel-title-line-height: (def: 32px, sm: 26px);

// Crate
$crate-background-color: $color-primary-light-blue;
$form-dropdown-border-color: #D6DEE3;
$font-size-form-fields: 16px;
$font-size-section-description-title: 28px;
// The variable was added due to a problem with the mixin for the buttons
$icon-short-arrow-right: "\e620";

// Page intro
$intro-title-font-family: $fontfamily-header;
$intro-title-font-size: (def: 48px, sm: 40px, xs: 28px);
$intro-title-line-height: (def: 52px, sm: 44px, xs: 32px);
$intro-text-font-family: $fontfamily-regular;
$intro-text-color: $color-light-black;

// Call to action
$cta-background-color: $color-primary;
$cta-background-light-color: $color-primary-light-blue;
$cta-title-font: $fontfamily-titles-normal;
$cta-title-transform: none;
$cta-title-font-size: (def: 40px, md: 28px);
$cta-title-line-height: (def: 44px, md: 32px);
$cta-button-text-color: $color-primary;

// Pushbox
$pushbox-background-color: $color-primary-light-blue;
$pushbox-pretitle-font: $fontfamily-roboto-regular;
$pushbox-text-color: $color-light-black;

// Section title
$font-size-section-title-large: 40px;
$line-height-section-title-large: 44px;
$letter-spacing-section-title-large: unset;

// Boxes
$font-size-boxes-title: 22px;
$line-height-boxes-title: 26px;

// Campaign - Media
$campaign-title-font-size: (def: 48px, md: 40px, sm: 28px);
$campaign-title-line-height: (def: 52px, md: 44px, sm: 32px);
$campaign-subtitle-font-size: (def: 16px);
$campaign-subtitle-line-height: (def: 22px);
$campaign-description-font-size: (def: 16px);
$campaign-description-line-height: (def: 22px);
$campaign-content-background-color: rgba($color-primary, 0.8);
$campaign-overlay-cta-margin-top: 16px;

// Campaign
$font-size-campaign-image-title-large: (def: 40px);
$line-height-campaign-image-title-large: (def: 44px);
$campaign-subtitle-font: $fontfamily-regular;
$campaing-image-h4-padding-bottom: 10px;
$campaign-light-background-color: $color-primary;
$campaign-buttons-margin: 24px;

// Item list
$font-size-items-list: 16px;
$line-height-items-list: 22px;

// Popup buy now
$popup-product-title-font-size: (def: 28px, xs: 22px);
$popup-product-title-line-height: (def: 32px, xs: 26px);
$popup-product-title-font-family: $fontfamily-titles-normal;
$popup-product-title-color: $home-color;
$popup-product-close-button-background: rgba($color-black, 0.27);
$popup-product-select-font-size: (def: 16px);
$popup-product-select-color: $color-primary;
$popup-product-select-font-family: $fontfamily-regular;
$popup-product-details-font-size: (def: 16px);
$popup-product-details-line-height: (def: 22px);
$popup-product-details-font-fammily: $fontfamily-regular;
$popup-product-details-color: $home-color;
$popup-product-details-border-color: #D6DEE3;

// Quotation (Testimonial)
$testimonials-quote-font-family: $fontfamily-titles-normal;
$testimonials-quote-color: #333333;
$testimonials-quote-letter-spacing: unset;
$testimonials-quote-info-font-family: $fontfamily-regular;
$testimonials-quote-info-color: #5e7e0c;
$testimonials-quote-left-img: '../img/quotation/ic-quotation-actimove-left.png';
$testimonials-quote-right-img: '../img/quotation/ic-quotation-actimove-right.png';
$testimonial-quotation-line-height: 26px;

// Carousel
$swiper-pagination-color: transparent;
$swiper-pagination-border-color: $color-primary;
$swiper-pagination-active-color: $color-primary;
$swiper-pagination-active-border-color: $color-primary;
$swiper-pagination-color-hover: transparent;
$swiper-pagination-border-color-hover: $color-olive;
$swiper-pagination-active-color-hover: $color-primary;
$swiper-pagination-active-border-color-hover: $color-primary;

// Carousel Alt
$carousel-title-font-size: (def: 48px, md: 40px, sm: 28px);
$carousel-title-line-height: (def: 52px, md: 44px, sm: 32px);
$carousel-content-dark-color: $home-color;
$carousel-content-lighten-color: $color-white;

// Gallery carousel
$font-size-gallery-carousel-heading: (def: 28px, sm: 22px);
$gallery-carousel-heading-line-height: (def: 32px, sm: 26px);

// Box carousel
$box-carousel-title-font-size: (def: 22px);
$box-carousel-title-line-height: (def: 26px);
$box-carousel-subtitle-font-size: (def: 16px);
$box-carousel-subtitle-line-height: (def: 22px);
$box-carousel-text-font-size: (def: 16px);
$box-carousel-text-line-height: (def: 22px);

// Product filter - Info panel
$info-panel-main-title-line-height: 26px;

// Search
$input-search-border-color: $color-primary;
$input-search-border-color-active: $color-primary;
$input-search-placeholder-color: $color-light-black;
$input-search-placeholder-color-active: $color-aluminium;
$input-search-color: $color-aluminium;
$btn-search-page-close-icon: 17px;
$btn-search-close-icon: 24px;
$pagination-hover-background-color: $color-primary;
$pagination-selected-background-color: $color-primary;
$pagination-selected-hover-background-color: $color-primary;
$pagination-arrow-icon-color: $color-primary;
$btn-search-icons-position-top: 24px;
$input-search-header-color: $color-black;

// Sitemap
$sitemap-list-title-font-size: 22px;

// Country selector
$country-selector-title-color: $color-light-black;
$country-selector-link-color: $color-primary;
$country-selector-border-color: $color-aluminium;

// Banner
$image-overlay-text-font-size: (def: 48px, sm: 28px);
$image-overlay-text-line-height: (def: 52px, sm: 32px);
$font-size-caption-image: 22px;
$font-size-caption-image-subheader: 16px;
$caption-background-transparent: linear-gradient(0deg, rgba($color-primary, 0.5) 0%, rgba($color-primary, 0.5) 100%), rgba($color-black, 0.27);

// Promo banner
$promo-banner-title-font: $fontfamily-titles-normal;
$promo-banner-title-font-size: (def: 40px, sm: 28px, xs: 22px);
$promo-banner-title-line-height: (def: 44px, sm: 32px, xs: 26px);
$promo-banner-title-font-weight: 400;
$promo-banner-desc-font: $fontfamily-lucidaGrande-bold-italic;
$promo-banner-desc-font-size: (def: 22px, xs: 16px);
$promo-banner-desc-line-height: (def: 26px, xs: 22px);
$promo-banner-bg-color: $color-primary;

// Country list submenu
$country-language-selected-color: #5E7E0C;